import React from 'react';

import { cdnStats } from '@siteData';

import CostReductionIcon from '@icons/costReduction.svg';
import PeakIcon from '@icons/peak.svg';
import SecurityIcon from '@icons/security.svg';
import VideoFeaturesIcon from '@icons/videoFeatures.svg';
import VideosIcon from '@icons/videos.svg';

export const networkCapacityFeature = {
  icon: <PeakIcon />,
  title: `${cdnStats.capacity.network} network`,
  description:
    'Experience lightning-fast content delivery with our robust global network, which has a private backbone connected to top-tier ISPs.',
};

export const videoCdnFeature = {
  icon: <VideosIcon />,
  title: 'CDN optimized for video',
  description:
    'Multi-layer caching systems, latency-based routing, delivery of all major HTTP video formats to any device.',
};

export const costReductionFeature = {
  icon: <CostReductionIcon />,
  title: 'Egress cost reduction',
  description: `CDN77 Storage and customized cache rules improve cache hit ratio to ${cdnStats.avgCashHit} or higher & reduce bandwidth costs.`,
};

export const securityFeature = {
  icon: <SecurityIcon />,
  title: 'Security & content protection',
  description:
    'Proprietary DDoS protection, Secure Tokens, IP & Geo-blocking, SSL, Multi‑DRM and more.',
};

export const additionalVideoFeaturesFeature = {
  icon: <VideoFeaturesIcon />,
  title: 'Additional video features',
  description: 'Encoding, transcoding, DVR & Timeshift, HTML5 player with audience analytics.',
};
